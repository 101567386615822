import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';

export default function MediaCard(props) {
  const { files } = props;
  console.log(files);

  const [info, setInfo] = useState([], {});

  useEffect(() => {
    const fetchUsersData = async () => {
      const res = await fetch(`${process.env.PUBLIC_URL}/ti.json`);
      const json = await res.json();
      setInfo(json);
    };

    fetchUsersData();
  }, []);

  const msToHMS = (ms) => {
    // 1- Convert to seconds:
    let seconds = ms / 1000;
    // 2- Extract hours:
    const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    const minutes = String(parseInt(seconds / 60)).padStart(2, '0'); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = String(parseInt(seconds % 60)).padStart(2, '0');
    return hours + ':' + minutes + ':' + seconds;
  };

  const getFc2Title = (infoState, fileName) => {
    const file = infoState.find((i) => i.name === fileName);
    if (file && 'title' in file) return file.title;
    return null;
  };

  const cards = files.map((file) => (
    <Grid item xs={3} key={file.id}>
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea href={file.webViewLink} target="_blank">
          <CardMedia
            component="img"
            height="140"
            image={file.thumbnailLink}
            alt="image"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {file.starred ? '🌟' : null}
              {file.videoMediaMetadata && file.videoMediaMetadata.width === 1920
                ? '📀'
                : null}
              {getFc2Title(info, file.name)
                ? getFc2Title(info, file.name)
                : file.name}
            </Typography>
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              color="text.secondary"
            >
              {file.videoMediaMetadata
                ? msToHMS(file.videoMediaMetadata.durationMillis)
                : null}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  ));

  return (
    <Grid container spacing={2}>
      {cards}
    </Grid>
  );
}
