import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Content from './Content';
import './App.css';

function App() {
  return (
    <GoogleOAuthProvider clientId="469870696599-ovi2g1us95vceq75j0vbrk920p05t267.apps.googleusercontent.com">
      <Content />
    </GoogleOAuthProvider>
  );
}

export default App;
